<template>
  <div>
    <b-card-code>
      <div
        v-if="isLoading === true"
        class="text-center mb-2"
      >
        <b-spinner
          variant="primary"
          label="Text Centered"
        />
      </div>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col
              v-if="expenseCategories"
              md="12"
            >
              <b-form-group
                label="Catégorie de dépense"
                label-for="expenseCategory"
              >
                <validation-provider
                  #default="{ errors }"
                  name="expenseCategory"
                  rules="required"
                >
                  <v-select
                    v-model="expense.expenseCategory"
                    :clearable="false"
                    placeholder="Choisissez une catégorie de dépense"
                    label="name"
                    :options="expenseCategories"
                    @input="getExpenseCategories()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="repositories"
              md="12"
            >
              <b-form-group
                label="Dépôt"
                label-for="Dépôt"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Dépôt"
                  rules="required"
                >
                  <v-select
                    v-model="expense.repository"
                    :clearable="false"
                    placeholder="Choisissez un dépôt"
                    label="name"
                    :options="repositories"
                    @input="getRepositories()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Montant"
                label-for="montant"
              >
                <validation-provider
                  #default="{ errors }"
                  name="montant"
                  rules="required"
                >
                  <b-form-input
                    v-model="expense.amount"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Montant"
                    type="number"
                    step="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              v-if="vendors"
              md="6"
            >
              <b-form-group
                label="Vendeur"
                label-for="Vendeur"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Vendeur"
                >
                  <v-select
                    v-model="expense.vendor"
                    :clearable="false"
                    placeholder="Choisissez un vendeur"
                    label="name"
                    :options="vendors"
                    @input="getVendors()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date"
                label-for="date"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
                >
                  <div class="d-flex align-items-center mb-1 mr-1">
                    <flat-pickr
                      v-model="expense.date"
                      class="form-control invoice-edit-input"
                    />
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-button
                variant="primary"
                type="submit"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-3"
                :class="{ 'disabled-cursor': isLoading || !isExpenseValid }"
                :disabled="isLoading || !isExpenseValid"
                @click.prevent="addExpense"
              >
                <b-icon
                  icon="plus"
                  aria-hidden="true"
                />
                Ajouter
              </b-button>
              <b-button
                variant="outline-secondary"
                type="reset"
                class="mt-3"
                @click.prevent="clearForm"
              >
                <b-icon
                  icon="arrow-counterclockwise"
                  class="mr-2"
                />
                Réinitialiser
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
  </div>
</template>

<script>
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'

/* import vSelect from 'vue-select' */
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'CreateExpense',
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      expenseCategories: [],
      vendors: [],
      repositories: [],
      expense: {
        expenseCategory: '',
        amount: '',
        date: null,
        repository: '',
        vendor: {
          id: '',
        },
      },
      required,
      isLoading: false,
    }
  },
  computed: {
    isExpenseValid() {
      return (
        this.expense.expenseCategory !== ''
        && this.expense.amount !== ''
      )
    },
  },

  created() {
    this.getExpenseCategories()
    this.getRepositories()
    this.getVendors()
  },
  methods: {
    async getRepositories() {
      const { data } = await axios.get('/api/repositories/')
      this.repositories = data
    },
    async getVendors() {
      const { data } = await axios.get('/api/vendors/')
      this.vendors = data
    },
    async getExpenseCategories() {
      const { data } = await axios.get('/api/expense-categories')
      this.expenseCategories = data
    },
    async addExpense() {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          try {
            this.isLoading = true
            await axios
              .post(
                '/api/expenses/create/', {
                  expense_category: this.expense.expenseCategory.id,
                  amount: this.expense.amount,
                  date: this.expense.date,
                  repository: this.expense.repository.id,
                  vendor: this.expense.vendor.id,
                },
              )
            this.clearForm()
            this.showToast('success', 'top-center', 'Dépense Ajouté avec avec succès')
            window.scrollTo(0, 0)
            this.isLoading = false
          } catch (error) {
            setTimeout(() => {
              this.showToast('danger', 'top-center', error.toString())
            }, 1000) // expected output: ReferenceError: nonExistentFunction is not defined
            this.isLoading = false
          }
        } else {
          setTimeout(() => {
            this.showToast('danger', 'top-center', 'champ obligatoire ! vérifier votre donnée !')
          }, 1000)
        }
      })
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
    formatPhone(e) {
      return String(e).substring(0, 8)
    },
    clearForm() {
      this.expense = {
        expenseCategory: '',
        amount: '',
      }
      this.$refs.simpleRules.reset()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.disabled-cursor {
  cursor: not-allowed;
  opacity: 0.8;
}
</style>
